<template>
	<div>
		<v-sheet class="dense-inputs">
			<v-row no-gutters>
                <div class="d-flex align-center pt-3">
                    <v-text-field
                        :placeholder="$t('message.no')"
                        :value="searchValues.title"
                        autocomplete="new-password"
                        class="filter-width-200 force-text-left"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchValues.title = $event"
                        @click:append="searchLcas('title')"
                        @click:clear="resetSearch()"
                        @keyup.enter.prevent="searchLcas('title')"
                    ></v-text-field>
                    <v-autocomplete
                        :items="filterItems.buyer"
                        :loading="loading.filterItems.buyer"
                        :placeholder="$t('message.buyer')"
                        class="filter-width-200 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Customer.title"
                        item-value="Customer.id"
                        solo
                        v-model="filterValues.buyer"
                        @change="filterLcas()"
                    ></v-autocomplete>
                    <v-autocomplete
                        :items="filterItems.supplier"
                        :loading="loading.filterItems.supplier"
                        :placeholder="$t('message.beneficiary')"
                        class="filter-width-200 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="Supplier.title"
                        item-value="Supplier.id"
                        solo
                        v-model="filterValues.supplier"
                        @change="filterLcas()"
                    ></v-autocomplete>
                    <v-btn
                        :loading="loading.clear"
                        class="ml-3 mt-0"
                        @click="clearFilters"
                    >{{ $t('message.clear') }}</v-btn>
                </div>
                <v-spacer></v-spacer>
                <ExportTableJsonWithAdd
                    :export-conditions="exportConditions"
                    :export-data="Lcas"
                    :export-fields="headers"
                    :export-source="'lcas'"
                    :has-add-function="true"
                    class="mt-3"
                    @add-action="newLetterOfCreditAdvice"
                ></ExportTableJsonWithAdd>
                <HelpPopUpV2 help-page-component="LcasListing"></HelpPopUpV2>
			</v-row>
		</v-sheet>
		<v-overlay
			:value="loading.lcas"
			absolute
			opacity="0.15"
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					></v-progress-circular>
				</v-col>
			</v-row>
		</v-overlay>
		<v-data-table
			:footer-props="{
				itemsPerPageOptions: [10,25,50,-1],
				showFirstLastPage: true
			}"
			:headers="headers"
            :hide-default-header="hideDefaultHeader"
            :hide-default-footer="hideDefaultFooter"
			:items="Lcas"
			:options="tableOptions"
			:server-items-length="totalLcas"
			calculate-widths
			class="mt-3 appic-table-light specification-table"
			dense
			id="letterOfCreditAdviceTable"
			item-key="Lca.id"
            @update:options="updateDataTable"
		>
            <template v-slot:item.Lca.Contract.title="{ item }">
                <span class="text-no-wrap font-weight-bold">{{ item.Lca.Contract.version != null ? [item.Lca.Contract.title, item.Lca.Contract.revision_no , item.Lca.Contract.partial_no].filter(Boolean).join('-') : item.Lca.Contract.title }}</span>
            </template>
			<template v-slot:item.Lca.lcadate="{ item }">
				{{ item.Lca.lcadate != '0000-00-00' ? formatDate(item.Lca.lcadate) : '' }}
			</template>
            <template v-slot:item.Lca.Office.title="{ item }">
                {{ item.Lca.Contract.salestype_id == 2 ? item.Lca.Supplier.otsname : item.Lca.Office.title }}
            </template>
			<template v-slot:item.Lca.lcalatestdate="{ item }">
				{{ item.Lca.lcalatestdate != '0000-00-00' ? formatDate(item.Lca.lcalatestdate) : '' }}
			</template>
			<template v-slot:item.Lca.Contract.amount="{ item }">
				<div>{{ lcaAmount(item) }}</div>
			</template>
			<template v-slot:item.Lca.lcalatestdate="{ item }">
				{{ item.Lca.expirydate != '0000-00-00' ? formatDate(item.Lca.expirydate) : '' }}
			</template>
			<template v-slot:item.Lca.expirydate="{ item }">
				{{ item.Lca.expirydate != '0000-00-00' ? formatDate(item.Lca.expirydate) : '' }}
			</template>
			<template v-slot:item.Lca.Loadingport.title="{ item }">
				{{ (item.Lca.Loadingport.title != null ? item.Lca.Loadingport.title  : '') }}
			</template>
			<template v-slot:item.Lca.Shippingport.title="{ item }">
				{{ (item.Lca.Shippingport.title != null ? item.Lca.Shippingport.title  : '') }}
			</template>
			<template v-slot:item.Lca.id="{ item }">
				<v-menu>
					<template v-slot:activator="{ on: menu }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on: tooltip }">
								<v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
									<v-icon>more_vert</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('message.moreActions') }}</span>
						</v-tooltip>
					</template>
					<v-list dense>
						<v-list-item class="font-sm" @click="updateLca(item.Lca.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updateLca') }}</v-list-item>
						<v-list-item class="font-sm" @click="cancelLca(item.Lca.id, (item.Lca.Contract.version != null ? [item.Lca.Contract.title, item.Lca.Contract.revision_no , item.Lca.Contract.partial_no].filter(Boolean).join('-') : item.Lca.Contract.title))"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelLca') }}</v-list-item>
<!--						<v-list-item class="font-sm" @click="viewPdf(item.Lca.id)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>-->
					    <PrintLetterOfCreditAdviceButton
                            :letter-of-credit-advice-id="item.Lca.id"
                            :list-mode="true"
                            :update-mode="true"
                        />
                    </v-list>
				</v-menu>
			</template>
		</v-data-table>
		<SimpleAlert
			:alert_message.sync="dialogs.error_message"
			:dialog.sync="dialogs.error"
			@dialog-closed="dialogClosed"
		></SimpleAlert>
	</div>
</template>

<script>
	import {formatDate} from "Helpers/helpers";
	// import SimpleAlert from "./SimpleAlert";
	import {numberFormat} from "../../helpers/helpers";
	import {mapGetters, mapActions} from "vuex";
	import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';
    import {api} from "Api";

    const ExportTableJsonWithAdd = () => import("./ExportTableJsonWithAdd");
    const SimpleAlert = () => import("./SimpleAlert");
    const HelpPopUpV2 = () => import("Components/Appic/Help/HelpPopUpV2");
    const PrintLetterOfCreditAdviceButton = () => import("./Printing/PrintLetterOfCreditAdviceButton.vue");

	export default {
		name: "LcasListing",
		components: {PrintLetterOfCreditAdviceButton, HelpPopUpV2, ExportTableJsonWithAdd, SimpleAlert},
		data() {
			return {
                currentFilter: null,
                currentSearch: null,
				dialogs: {
					error:  false,
					error_message: ""
				},
                exportConditions: {},
                filterItems: {
				    buyer: [],
                    supplier: []
                },
				filterOptions: {
					status: {
						Status: {
							id: 1,
							title: 'open'
						}
					}
				},
                filterValues: {
				    buyer: null,
                    supplier: null
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
				loading: {
					filter: {
						buyers: false,
                        suppliers: false
					},
                    filterItems: {
                        buyer: null,
                        supplier: null
                    },
                    lcas: false,
					search: false
				},
				searchField: 'Lca.Contract.title', //default search field
				searchTerm: null,
                searchValues: {
                    title: null
                },
				tableOptions: {},
			}
		},
		computed: {
			...mapMultiRowFields('lca',{
				Lcas: 'current.Lcas'
			}),
			...mapFields('lca',{
				totalLcas: 'current.totalLcas'
			}),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
			...mapGetters([
				'currencies'
			]),
			formatDate: () => formatDate,
			headers () {
				let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Lca.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.date'),
                        value: 'Lca.lcadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 2,
                        text: this.$t('message.number'),
                        value: 'Lca.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.buyer'),
                        value: 'Lca.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.beneficiary'),
                        value: 'Lca.Office.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
                    },
                    {
						id: 5,
						text: this.$t('message.bank'),
						value: 'Lca.Bank.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
					},
					{
						id: 6,
						text: this.$t('message.amount'),
						value: 'Lca.Contract.amount',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
					},
                    {
                        id: 7,
                        text: this.$t('message.lcReceived'),
                        value: 'Lca.lcalatestdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: false
                    },
                    {
						id: 8,
						text: this.$t('message.lcLsd'),
						value: 'Lca.lcalatestdate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 9,
						text: this.$t('message.lcExpiry'),
						value: 'Lca.expirydate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 10,
						text: this.$t('message.pol'),
						value: 'Lca.Loadingport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					},
					{
						id: 11,
						text: this.$t('message.pod'),
						value: 'Lca.Shippingport.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        sortable: true
					}
				]
				return headers
			},
			searchFields() {
				return this.headers.filter((header)=>header.searchable === true)
			},
		},
		methods: {
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
			...mapActions('lca',{
				cancelLcaById: 'cancelLcaById',
				getAllLcas: 'getAllLcas',
				searchAllLcas: 'searchAllLcas'
			}),
			async cancelLca (val, title) {
				if(await this.$root.$confirm(this.$t('message.cancelLca') + ' ' + (title != null ? title : ''), this.$t('message.confirmations.continueLcaCancelAction'), {color: 'orange'})){
					this.cancelLcaById(val).then((status) => {
						if(status == 'done'){
							this.$toast.success(this.$t('message.successes.lcaDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'check_circle_outline'
								}
							)
							this.loadLcas()
						} else {
							this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
								{
                                    classes: ['icon-float-left'],
									icon: 'error_outline'
								}
							)
						}
					})
					.catch(()=>{
						this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
							{
                                classes: ['icon-float-left'],
								icon: 'error_outline'
							}
						)
					})
					.finally(()=>{

					})
				}
			},
            clearFilters() {
                this.loading.clear = true
                this.filterValues.buyer = null
                this.filterValues.supplier = null
                this.searching = false
                this.searchValues.title = null
                this.tableOptions.page = 1
                this.tableOptions.sortBy[0] = 'Lca.lcadate'
                this.tableOptions.sortDesc[0] = true
                this.loading.clear = false
                this.filterLcas()
            },
			currency(currency_id) {
				return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
			},
			currencyFormatPrecision (currency) {
				if(isNaN(currency)){
					return this.allCurrencies.find((c) => c.Currency.code == currency)?.Currency?.formatPrecision
				} else {
					return this.allCurrencies.find((c) => c.Currency.id == currency)?.Currency?.formatPrecision
				}
			},
			dialogClosed () {
				this.dialogs.error = false;
				this.dialogs.error_message = ''
			},
            filterLcas() {
                this.searchValues.title = null
                let noValues = true
                const keys = Object.keys(this.filterValues)
                keys.forEach((key, index) => {
                    if(this.filterValues[key] != null) noValues = false
                })
                if(noValues == false) {
                    let conditions = []
                    if(this.filterValues.buyer != null) conditions.push({field: 'Lca.Contract.customer_id', value: this.filterValues.buyer})
                    if(this.filterValues.supplier != null) conditions.push({field: 'Lca.Contract.supplier_id', value: this.filterValues.supplier})

                    if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                    if(_.isEqual(this.currentFilter,this.filterValues) === false) {
                        this.tableOptions.page = 1
                        this.currentFilter = _.cloneDeep(this.filterValues)
                    }

                    if(_.has(this.tableOptions, 'sortBy') == false) {
                        this.tableOptions['sortBy'] = ['Lca.lcadate']
                    }
                    if(this.tableOptions.sortBy.length == 0){
                        this.tableOptions['sortBy'] = ['Lca.lcadate']
                    }
                    if(_.has(this.tableOptions, 'sortDesc') == false){
                        this.tableOptions['sortDesc'] = [false]
                        if(this.tableOptions.sortBy[0] == 'Lca.lcadate') {
                            this.tableOptions['sortDesc'] = [true]
                        }
                    }
                    if(this.tableOptions.sortBy[0] == 'Lca.lcadate' && this.tableOptions.sortDesc.length == 0){
                        this.tableOptions['sortDesc'] = [true]
                    }

                    let order = [{field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'}]

                    let payload = {
                        tableOptions: this.tableOptions,
                        conditions: conditions,
                        order: order
                    }

                    this.hideDefaultFooter = false
                    this.loading.lcas = true;
                    this.searchAllLcas(payload)
                        .then(() => {
                            this.loading.lcas = false;
                        })
                        .catch(()=>{
                            this.loading.lcas = false;
                        })
                        .finally(()=>{
                            this.loading.lcas = false;
                        })
                } else {
                    this.loadLcas()
                }
            },
			formatThisNumber(value,format){
				return numberFormat(value,format)
			},
			lcaAmount (item) {
				return item.Lca.Contract.currency_id != null ? this.currency(item.Lca.Contract.currency_id) + ' ' + this.formatThisNumber(item.Lca.Contract.amount,this.currencyFormatPrecision(item.Lca.Contract.currency_id)) : ''
			},
            loadFilterItems(filter) {
                return new Promise((resolve, reject) => {
                    if(filter && filter.length > 0) {
                        this.loading.filterItems[filter] = true
                        api
                            .get('/lcas/filter-options/' + filter)
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.filterItems[filter] = response.data.data
                                    this.loading.filterItems[filter] = false
                                    resolve('done')
                                } else {
                                    this.loading.filterItems[filter] = false
                                    reject('error')
                                }
                            })
                            .catch(error => {
                                this.loading.filterItems[filter] = false
                                reject(error)
                            })
                    } else {
                        reject('Filter empty')
                    }
                })
            },
			loadLcas() {
				// this.loading.lcas = true;
				// let payload = {
				// 	tableOptions: this.tableOptions,
				// 	filterOptions: this.filterOptions
				// }
                this.hideDefaultFooter = false
                this.loading.lcas = true;

                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10
                if(this.tableOptions.page == null) this.tableOptions.page = 1

                let payload = {
                    tableOptions: this.tableOptions,
                    // filterOptions: this.filterOptions
                }
				this.getAllLcas(payload)
					.then(()=>{
						this.loading.lcas = false;
					})
					.catch(()=>{
						this.loading.lcas = false;
					})
					.finally(()=>{
						this.loading.lcas = false;
					})
			},
            newLetterOfCreditAdvice() {
                let tab = window.open('/v1/lcas/add', '_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
			resetSearch (filter = null) {
				// this.hideDefaultFooter = false
				// this.loading.lcas = false
				// this.searchField = 'Lca.Contract.title'; //default search field
				// this.searchTerm = null;
				// this.tableOptions.itemsPerPage = 10
				// this.filterOptions = {};

                this.searching = false
                this.hideDefaultFooter = false
                this.filterValues.buyer = null
                this.tableOptions.page = 1
                if(this.tableOptions.itemsPerPage == null) this.tableOptions.itemsPerPage = 10

                // switch(filter){
                //     case 'eta':
                //     case 'etd':
                //     case 'ets':
                //     case 'osd':
                //         this.searchValues[filter] = []
                //         break
                // }

                this.tableOptions.sortBy = []
                this.tableOptions.sortDesc = []
                this.filterLcas()
			},
			searchLcas (field) {
                if(this.searchValues[field] == null || this.searchValues[field].length == 0){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchTerm')
                } else {
                    //TODO search lcas
                    // this.filterOptions = {}
                    // this.hideDefaultFooter = true
                    // this.loading.ipas = true;
                    // this.loading.search = true;
                    // let payload = {
                    //     tableOptions: this.tableOptions,
                    //     search: {
                    //         field: this.searchField,
                    //         value: this.searchTerm
                    //     }
                    // }
                    this.searching = true
                    this.filterValues.buyer = null
                    this.filterValues.supplier = null

                    const keys = Object.keys(this.searchValues)
                    keys.forEach((key, index) => {
                        if(key != field) {
                            this.searchValues[key] = null
                        }
                    })

                    let fieldName = null
                    let sortFieldName = null
                    switch(field){
                        case 'title':
                            fieldName = 'Lca.title'
                            sortFieldName = 'Lca.lcadate'
                            break
                    }

                    this.loading.lcas = true

                    let payload = {}
                    let order = []

                    if (_.isEqual(this.currentSearch, this.searchValues) === false) {
                        //new search = new sort
                        order.push({field: fieldName, direction: ['title'].includes(field) ? 'ASC' : 'DESC'})
                        this.tableOptions.sortBy[0] = sortFieldName
                        this.tableOptions.sortDesc[0] = ['title'].includes(field) ? false : true
                        this.currentSearch = _.cloneDeep(this.searchValues)
                        //new search new page
                        this.tableOptions.page = 1
                    } else {
                        //same search new sort
                        if(this.tableOptions.sortBy.length == 0){
                            this.tableOptions.sortBy[0] = sortFieldName
                        }
                        order.push({field: this.tableOptions.sortBy[0], direction: this.tableOptions.sortDesc[0] ? 'DESC' : 'ASC'})
                    }

                    if(fieldName != null) {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [
                                {
                                    field: fieldName,
                                    value: this.searchValues[field]
                                }
                            ],
                            order: order
                        }
                    } else {
                        payload = {
                            tableOptions: this.tableOptions,
                            conditions: [],
                            order: order
                        }
                    }

                    this.searchAllLcas(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.lcas = false;
                        })
				}
			},
            updateDataTable(options) {
                this.tableOptions = options
                if(this.searching === true)  {
                    // check which field is not empty
                    let field = null
                    if(this.searchValues.title != null) field = 'title'
                    this.searchLcas(field)
                } else {
                    this.filterLcas()
                }
            },
			updateLca (val) {
                let tab = window.open('/v1/lcas/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_lca', params: { lcaId : val}}).catch(err => {})
			},
			viewPdf (lcaId) {
                let lca = this.Lcas.find( l => l.Lca.id == lcaId)
                let document = encodeURIComponent((lca.Lca.Contract.version != null ? [lca.Lca.Contract.title, lca.Lca.Contract.revision_no , lca.Lca.Contract.partial_no].filter(Boolean).join('-') : lca.Lca.Contract.title) + '_LCA')
				let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
					process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
					+ process.env.VUE_APP_BASE_URL
					+ '/v1/lcas/print/'
					+ lcaId
					+ '/'
					+ uuidv4()
					+ '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
					, "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
			},
		},
		watch: {
			// filterOptions: {
			// 	handler(){
			// 		if(!this.loading.search) {
			// 			this.loadLcas()
			// 		}
			// 	},
			// 	deep: true
			// },
			// tableOptions: {
			// 	handler(){
			// 		this.loadLcas()
			// 	},
			// 	deep: true
			// },
		},
		created(){
            this.searchValues.title = null
            this.filterValues.buyer = null
            this.filterValues.supplier = null

            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            this.loadLcas()

            if(this.filterItems.buyer.length == 0) this.loadFilterItems('buyer')
            if(this.filterItems.supplier.length == 0) this.loadFilterItems('supplier')
		}
	}
</script>

<style>
.filter-width-200 {
    width: 200px !important;
    max-width: 200px !important;
}
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>